@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');

:root {
  --color1: #913276;
  --color2: #F6C0DB;
  --color3: #be9592;
  --color4: #F4D1CF;
  --color5: #e6d88e;
  --heading-family: "Vidaloka";
  --body-family: "Roboto";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: var(--heading-family); */
  /* color: var(--color3) !important; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black !important; */
  /* color: #c79645 !important; */
  /* color: whitesmoke !important; */
  font-family: var(--body-family) !important;
  overflow-x: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

section {
  margin-top: 8rem;
}

.btn-primary {
  background-color: var(--color4) !important;
  border-color: var(--color4) !important;
  color: black !important;
  transition: transform 0.3s ease-in-out !important;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled) {
  background-color: var(--color3) !important;
  border-color: var(--color3) !important;
  box-shadow: none !important;
}

.btn-primary:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

.aboutContainer {
  height: 75vh;
  width: 75vw;
  position: relative;
}

.aboutCreators {
  max-width: 50vw;
  max-height: 50vh;
}

.aboutPhoto {
  z-index: 0;
  max-width: 90vw;
  max-height: 90vh;
}

.aboutText {
  max-width: 80%;
}

.aboutTextOverlay {
  position: absolute;
  background-color: rgb(56, 56, 56);
  z-index: 1;
  margin-left: 32rem;
  margin-top: 13rem;
  max-height: 60vh;
  max-width: 30vw;
  border-radius: 8px;
  padding: 30px;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consultation {
  text-decoration: none !important;
}

.contentStrip {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vcenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

/* .carouselSlide {
  max-width: 50vw;
  max-height: 50vh;
} */

.contentStrip {
  min-width: 75vw;
  max-width: 95vw;
  margin-top: 10rem;
}

.homeBlurb {
  line-height: 2.5rem;
  text-align: center;
  max-width: 95vw;
}

.homeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  max-width: 70vw;
  bottom: 2%;
  left: 40%;
}

.homeTriplet {
  max-height: 50vh;
  max-width: 30vw;
}

.indent {
  text-indent: 15%;
}

.iframeContainer {
  display: flex;
}

.iframeCenter {
  align-content: center;
}

.quote {
  line-height: 3.5rem;
  color: var(--color1);
  font-weight: bold;
}

.quoteStrip {
  margin-top: 5rem;
}

.navBrand {
  font-family: var(--heading-family) !important;
  /* color: whitesmoke !important; */
  /* font-weight: 700 !important; */
}

.gradient {
  background-image: -webkit-linear-gradient(var(--color2), white 90%);
}

.modalWidth {
    width: 90%;
}

.meetCreators {
  padding: 3rem;
  margin-top: 10rem;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggle:focus {
    outline: none;
 }

.navLink {
  /* color: whitesmoke !important; */
  font-weight: 500 !important;
  font-family: var(--heading-family);
  font-weight: bold;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
    outline: none;
}

.splashImage {
  max-width: 98vw;
  max-height: 90vh;
}

.splashWrapper {
  max-width: 100%;
}

.serviceBlurb {
  line-height: 2.5rem;
  text-align: center;
  margin: 3rem;
}

.serviceTriplet {
  max-height: 30vh;
  max-width: 25vw;
}

.tagLine {
  font-family: var(--heading-family);
}
.tagLineContainer {
  max-width: 50vw;
  margin-left: 10vw;
}
.underConstruction {
  max-width: 90vw;
}
